import * as React from 'react';

import HorizontalSectionLine from '../../inc/horizontalSectionLine';

import Layout from '../../layout';
import LeftRight from '../../inc/leftRight';
import Left from '../../inc/leftRight/left';
import Right from '../../inc/leftRight/right';

import Woman from '../../../images/__new/vision/oval_photo_1.png';
import SaxophonistImage from '../../../images/__new/vision/img5.jpg';

const ThirdSection = (props) => {
  return (
    <div className='visionThirdSection'>
      <LeftRight className='rectrangleContainer'>
        <div className='bgRectangle' />
        <Layout>
          <Left className='leftVisionContainer'>
            <div className='sectionTitle'>
              <h2 className='sectionTitleText'>
                <span className='yellowText'>
                  MAKING MUSIC AS INTENSELY TANGIBLE AS&nbsp;POSSIBLE
                </span>
              </h2>
            </div>
            <HorizontalSectionLine />
            <div className='sectionDescription'>
              <p className='text'>
                Music shapes us even before we are&nbsp;born. <br />
                Music touches us in our deepest inner&nbsp;being. <br />
                Music turns a bad day into a good&nbsp;day. <br />
                Music turns a tiresome car ride into a&nbsp;road&nbsp;trip.{' '}
                <br />
                Music provides us with energy and the joy of &nbsp;life. <br />
                Music floods our brain with&nbsp;dopamine. <br />
                Music pushes us to top&nbsp;achievements. <br />
                Music makes us overcome psychological&nbsp;boundaries. <br />
                Music awakens&nbsp;memories. <br />
                Music relieves&nbsp;pain. <br />
                Music connects.
              </p>
              <p className='text'>
                Hardly any other art has as much power as music. There are few
                things in life that can make us happy in such a simple way and
                have as great an influence on our lives as music.
              </p>
            </div>
          </Left>
          <Right>
            <div className='imageContainer'>
              <img src={Woman} alt='Woman listening music' loading='lazy' />
            </div>
          </Right>
        </Layout>
      </LeftRight>
      <LeftRight className='underRectangleSection'>
        <Layout>
          <Left className='leftVisionContainer'>
            <div className='sectionDescription'>
              <p className='text'>
                Music gives both musicians and listeners a thrill - whether it's
                a live concert or a recording. Music speaks to all parts of our
                brain. When musicians free their minds, they reach a musical
                level in which they can rise above themselves and overcome their
                musical limits. Music has direct access to our emotions. This is
                how music is created, which touches us as human being deep
                inside of our soul. Music - that which we cannot describe.
              </p>
              <p className='text'>
                <span className='textBold'>Only feel.</span>
              </p>
              <p className='text'>
                Anyone who has ever experienced special musical moment knows
                what we are talking about. Music that touches us, that grips us
                and drives us, and which plays no the entire keyboard of human
                motivation. This is what makes music the soundtrack of our
                lives.
              </p>
              <p className='text'>
                <span className='textBold'>
                  Let's imagine for a moment a life without music - no, let's
                  better not. As Nietzsche once said: "Without music, life would
                  be a mistake."
                </span>
              </p>
            </div>
          </Left>
          <Right>
            <div className='imageContainer'>
              <img src={SaxophonistImage} alt='Saxophonist' loading='lazy' />
            </div>
          </Right>
        </Layout>
      </LeftRight>
    </div>
  );
};

export default ThirdSection;
