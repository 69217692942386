import * as React from 'react';

import Layout from '../../layout';

import SectionBg1 from '../../../images/__new/mainpage/img7.jpg';
import SectionBg2 from '../../../images/__new/vision/img7.jpg';

const FooterRectangleSection = () => {
  return (
    <section className='footerVisionRectangleSection'>
      <div className='firstSection'>
        <div className='bgRectangle'></div>
        <Layout>
          <div className='textContainer'>
            <p className='text'>
              GRADIVARI will carry on the legacy of Adolphe Sax and combine it
              with innovative products. We always strive to find better ways to
              help musicians realize their full potential.
            </p>
            <p className='text'>
              Our vision is to bring value to the world of music, to inspire it
              and to foster a creative community in which musicians around the
              world can evolve, inspire and express themselves.
            </p>
            <p className='sectionTitleText'>
              <span className='yellowText'>
                GRADIVARI is here to&nbsp;play a&nbsp;symphony –
                not&nbsp;a&nbsp;note.
              </span>
            </p>
          </div>
        </Layout>
      </div>
      <div className='secondSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg1}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='sectionTitle'>
            <p className='sectionTitleText'>BLACK IS ALSO</p>
            <p className='sectionTitleText'>
              <span className='yellowText'>CLASSICAL.</span>
            </p>
          </div>
        </Layout>
      </div>
      <div className='thirdSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg2}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='textContainer'>
            <p className='sectionTitleText'>
              MUSIC HAS NO
              <br /> BOUNDARIES.
              <span className='yellowText'>
                NEVER.
                <br />
                NOWHERE.
              </span>
            </p>
          </div>
        </Layout>
      </div>
    </section>
  );
};

export default FooterRectangleSection;
