import * as React from 'react';

import Layout from '../../layout';
import LeftRight from '../../inc/leftRight';
import Left from '../../inc/leftRight/left';
import Right from '../../inc/leftRight/right';
import HorizontalSectionLine from '../../inc/horizontalSectionLine';

import SaxophonistImage1 from '../../../images/__new/vision/img2.jpg';
import SaxophonistImage2 from '../../../images/__new/vision/img3.png';

const FirstSection = (props) => {
  return (
    <section className={'visionFirstSection'}>
      <LeftRight className='aboveRectrangleContainer'>
        <Layout>
          <Left className='leftVisionContainer'>
            {' '}
            <div className='sectionTitle'>
              <h2 className='sectionTitleText'>
                Perfection{' '}
                <span className='yellowText'>through&nbsp;freedom.</span>
              </h2>
            </div>
            <HorizontalSectionLine />
            <div className='sectionDescription'>
              <p className='text'>
                Music helps us communicate with each other at a much more
                intense and emotional level. At a level that language or images
                never reach.
              </p>
              <p className='text'>
                That's why the saxophone resembles the human voice more than any
                other instrument. <strong>The SAX</strong> is meant to serve as
                a musical communication tool between musicians and musicians and
                musicians and audience. That was the brilliant vision of Adolphe
                Sax.
              </p>

              <p className='text'>
                Adolphe Sax wanted to connect musicians and audiences even more
                deeply through the saxophone. Playing the saxophone should
                create a kind of communication, an exchange. The experience of
                music should be more intense for musicians and audience. There
                should be no more boundaries between music, musicians and
                audiences. Sax wanted musicians to break down all musical
                boundaries in order to achieve musical perfection.
              </p>
              <p className='text'>
                We at GRADIVARI believe that the perfection of art can only be
                achieved through free play. Only if you as a musician can play
                completely freely can you ultimately discover new things. Only
                then can you develop musically. And create music that has the
                power and intensity to overcome musical boundaries.
              </p>
            </div>
          </Left>
          <Right>
            <div className='imageContainer'>
              <img src={SaxophonistImage1} alt='Saxophonist' loading='lazy' />
            </div>
          </Right>
        </Layout>
      </LeftRight>
      <LeftRight className='rectrangleContainer'>
        <div className='bgRectangle' />
        <Layout>
          <Left className='leftVisionContainer'>
            <div className='sectionDescription'>
              <p className='text'>
                We at <strong>GRADIVARI</strong> would like to continue to
                foster the courageous and visionary spirit of Adolphe Sax. Sax
                never gave up on getting the best out of music. He believed in
                himself and his vision to overcome musical boundaries, to exceed
                expectations and to break with traditions.
              </p>
              <p className='text'>
                We know how musicians think and feel. We know what they want,
                what they dream of, and we understand their problems. Why?
                Because we have also been through this. Because we are musicians
                too. We have learned from this and now we want to help them. We
                want to change something for them, for the music world. We want
                to take them on a journey where there are no creative boundaries
                — and no limits. We want to create a culture together with them
                — a community. We want to empower musicians to overcome musical
                boundaries. We want to inspire musicians to believe in their
                vision, we want to motivate them to have the courage to try new
                things through music to create something extraordinary.
              </p>
              <p className='text'>
                We want to encourage more people to make music, just as we
                humans have always made music, to communicate without words,
                across borders. We want to encourage, inspire and enable young
                musicians to express themselves musically.
              </p>
              <p className='text'>
                Whether music discoverer, music student, hobby musician, music
                teacher or professional musician — we want to give musicians the
                inspiration and the tools to overcome their musical limits.
              </p>
              <p className='text'>
                <span className={'textBold'}>Music has no boundaries.</span>
                <span className={'textBold'}>Never.</span>
                <span className={'textBold'}>Nowhere.</span>
                <br />
                <br />
                <span className={'textBold'}>GRADIVARI.</span>
              </p>
            </div>
          </Left>
          <Right>
            <div className='imageContainer'>
              <img src={SaxophonistImage2} alt='Saxophonist' loading='lazy' />
            </div>
          </Right>
        </Layout>
      </LeftRight>
    </section>
  );
};

export default FirstSection;
