import * as React from 'react';

import HeroImg from '../../../../src/images/__new/vision/img1.jpg';

const HeaderContent = (props) => {
  return (
    <div className={'headerContent visionHeaderContent'}>
      <div className={'heroImage'}>
        <img src={HeroImg} alt={'Saxophonist'} />
      </div>
      <h1 className='title'>
        When you begin to&nbsp;see the <br />
        possibilities of music, you desire to
        <br /> do something really good for <br />
        people.
      </h1>
      <p className='headerUnderTitle'>John Coltrane</p>
    </div>
  );
};

export default HeaderContent;
